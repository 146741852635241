import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import router from './router/index.js'
import './style/normalize.css'
import 'lib-flexible'

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()

new Vue({
  router,
  axios,
  render: h => h(App),
}).$mount('#app')
