import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // 路由重定向 默认显示当前路由页面
    redirect: '/index',
    // 后置路由守卫，需要每个都配置  meta
    meta: { title: '首页' }
  },
  {
    name: 'index',
    path: '/index',
    component:resolve => require(['@/view/index'],resolve),
    meta: { title: '首页' },
  },
  {
    name: 'identityAuthentication',
    path: '/identityAuthentication',
    component:resolve => require(['@/view/identityAuthentication'],resolve),
    meta: { title: '身份校验' },
  },
  {
    name: 'success',
    path: '/success',
    component:resolve => require(['@/view/success'],resolve),
    meta: { title: '验证成功' },
  },
  {
    name: 'error',
    path: '/error',
    component:resolve => require(['@/view/error'],resolve),
    meta: { title: '验证失败' },
  },
  {
    name: 'realNameAuthentication',
    path: '/agreement/realNameAuthentication',
    component:resolve => require(['@/view/agreement/realNameAuthentication'],resolve),
    meta: { title: '实名认证用户隐私协议' },
  },
  {
    name: 'userAgreement',
    path: '/agreement/userAgreement',
    component:resolve => require(['@/view/agreement/userAgreement'],resolve),
    meta: { title: '用户协议' },
  },
  {
    name: 'privacyPolicy',
    path: '/agreement/privacyPolicy',
    component:resolve => require(['@/view/agreement/privacyPolicy'],resolve),
    meta: { title: '隐私政策' },
  }
]

const router = new VueRouter({
  routes,
  // mode:'hash'  // 有#
})

// 前置路由守卫
router.beforeEach((to, from, next) => {
  next()
})

// 后置路由守卫  to，from
router.afterEach((to) => {
  document.title = to.meta.title || ''
})

export default router
